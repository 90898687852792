<template>
  <v-app>
    <v-app-bar app color="#1D6D7D" height="100" elevation="4" absolute>
      <div class="d-flex align-center">
        <v-img
          alt="Viortis"
          class="shrink mr-2"
          contain
          src="./assets/logo-20.png"
          transition="scale-transition"
          height="85"
          width="300"
        />
      </div>
      <v-spacer></v-spacer>
      <v-btn
        text
        class="white--text"
        @click="openInstruction()"
        v-if="loginInfo != null"
      >
        <v-icon color="white" left>mdi-file-document</v-icon>
        {{
          currentLanguage == "ru"
            ? string_values.find((x) => x.value == "instruction").nameRu
            : string_values.find((x) => x.value == "instruction").nameEn
        }}
      </v-btn>
      <v-btn-toggle color="teal">
        <v-btn
          small
          :color="currentLanguage == 'ru' ? 'red' : 'teal'"
          class="white--text"
          @click="setLanguage('ru')"
        >
          <span>RU</span>
        </v-btn>
        <v-btn
          small
          :color="currentLanguage == 'en' ? 'red' : 'teal'"
          class="white--text"
          @click="setLanguage('en')"
        >
          <span>EN</span>
        </v-btn>
      </v-btn-toggle>
      <v-btn
        text
        class="white--text"
        @click="logOut()"
        v-if="loginInfo != null"
      >
        {{
          currentLanguage == "ru"
            ? string_values.find((x) => x.value == "logout").nameRu
            : string_values.find((x) => x.value == "logout").nameEn
        }}
        <v-icon color="white" right>mdi-logout</v-icon>
      </v-btn>
      <template v-slot:extension v-if="loginInfo != null">
        <v-tabs
          background-color="#3FA0A5"
          center-active
          grow
          v-model="selectedTabId"
        >
          <v-tabs-slider color="red"></v-tabs-slider>

          <v-tab @click="goToMainPage()" class="white--text">
            <v-icon left color="white">mdi-home</v-icon>
            {{
              currentLanguage == "ru"
                ? string_values.find((x) => x.value == "home").nameRu
                : string_values.find((x) => x.value == "home").nameEn
            }}
          </v-tab>
          <v-tab
            @click="goToControlPanel()"
            v-if="userRole == 'Admin'"
            class="white--text"
          >
            <v-icon left color="white">mdi-cog</v-icon>
            {{
              currentLanguage == "ru"
                ? string_values.find((x) => x.value == "control_panel").nameRu
                : string_values.find((x) => x.value == "control_panel").nameEn
            }}
          </v-tab>
          <v-tab @click="goToUserReports()" class="white--text">
            <v-icon left color="white">mdi-chart-tree</v-icon>
            {{
              currentLanguage == "ru"
                ? string_values.find((x) => x.value == "my_reports").nameRu
                : string_values.find((x) => x.value == "my_reports").nameEn
            }}
          </v-tab>
          <v-tab @click="createReport()" class="white--text">
            <v-icon left color="white">mdi-chart-box</v-icon>
            {{
              currentLanguage == "ru"
                ? string_values.find((x) => x.value == "create_report").nameRu
                : string_values.find((x) => x.value == "create_report").nameEn
            }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-main class="mt-2">
      <router-view></router-view>
      <v-snackbar
        v-model="snackbar.show"
        :timeout="snackbar.timeout"
        bottom
        :color="snackbar.color"
      >
        {{ snackbar.text }}
      </v-snackbar>
    </v-main>
    <v-overlay :value="reportLoader">
      <div id="ajaxLoader" class="loader-block">
        <div class="loader-container">
          <svg
            class="loader"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 340 340"
          >
            <circle cx="170" cy="170" r="160" stroke="#F55246" />
            <circle cx="170" cy="170" r="135" stroke="#1D6D7D" />
            <circle cx="170" cy="170" r="110" stroke="#F55246" />
            <circle cx="170" cy="170" r="85" stroke="#48A4A9" />
          </svg>
        </div>
      </div>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { string_values } from "./assets/strings/string_values";
export default {
  name: "App",
  components: {},
  data: () => ({
    string_values: string_values,
    currentLanguage: "ru",
  }),
  computed: {
    ...mapGetters({
      getSnackbar: "snackbar/GET_SNACKBAR",
      getReportLoader: "loader/GET_REPORT_LOADER",
      getLoginInfo: "identity/GET_LOGIN_INFO",
      getUserRole: "identity/GET_USER_ROLE",
      getSelectedTabId: "tab/GET_SELECTED_TAB_ID",
    }),
    snackbar() {
      return this.getSnackbar;
    },
    reportLoader() {
      return this.getReportLoader;
    },
    loginInfo() {
      return this.getLoginInfo;
    },
    userRole() {
      return this.getUserRole;
    },
    selectedTabId() {
      return this.getSelectedTabId;
    },
  },
  methods: {
    ...mapActions({
      commitUserRole: "identity/COMMIT_USER_ROLE",
      commitSelectedTabId: "tab/COMMIT_TAB_ID",
    }),
    ...mapMutations({
      setLoginInfo: "identity/SET_LOGIN_INFO",
    }),
    logOut() {
      this.setLoginInfo(null);
      localStorage.removeItem("identity");
      this.$router.push({
        path: "/login",
      });
    },
    createReport() {
      this.$router.push({
        path: "/report/0",
      });
    },
    goToMainPage() {
      this.$router.push({
        path: "/main",
      });
    },
    goToUserReports() {
      this.$router.push({
        path: "/list",
      });
    },
    goToControlPanel() {
      this.$router.push({ path: "/admin" });
    },
    setLanguage(language) {
      localStorage.setItem("currentLanguage", language);
      window.location.reload();
    },
    openInstruction() {
      window.open(
        window.location.protocol +
          "//" +
          window.location.host +
          "/static/instruction.pdf",
        "_blank"
      );
    },
  },
  created() {
    var currentLanguage = localStorage.getItem("currentLanguage");
    if (currentLanguage != null) {
      this.currentLanguage = currentLanguage;
    } else {
      localStorage.setItem("currentLanguage", "ru");
    }
    this.commitSelectedTabId(0);
    this.commitUserRole();
  },
};
</script>

<style scoped>
.loader-block {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  cursor: pointer;
}

.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loader {
  max-width: 22rem;
  width: 100%;
  height: auto;
  stroke-linecap: round;
}

.loader circle {
  fill: none;
  stroke-width: 3.5;
  -webkit-animation-name: preloader;
  animation-name: preloader;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-transform-origin: 170px 170px;
  transform-origin: 170px 170px;
  will-change: transform;
}

.loader circle:nth-of-type(1) {
  stroke-dasharray: 550;
}

.loader circle:nth-of-type(2) {
  stroke-dasharray: 500;
}

.loader circle:nth-of-type(3) {
  stroke-dasharray: 450;
}

.loader circle:nth-of-type(4) {
  stroke-dasharray: 300;
}

.loader circle:nth-of-type(1) {
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
}

.loader circle:nth-of-type(2) {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.loader circle:nth-of-type(3) {
  -webkit-animation-delay: -0.45s;
  -moz-animation-delay: -0.45s;
  animation-delay: -0.45s;
}

.loader circle:nth-of-type(4) {
  -webkit-animation-delay: -0.6s;
  -moz-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

@-webkit-keyframes preloader {
  50% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes preloader {
  50% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
